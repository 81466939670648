import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { CACHE_KEYS } from '@lib/fetch/constants';
import { useTenantById } from '@lib/tenants/useTenantById';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/system';

export const CurrencySymbol: FC = () => {
  const { t } = useTranslation();
  const { data: tenantById, isLoading: isLoadingTenantById } = useTenantById(
    CACHE_KEYS.tenantById
  );

  if (isLoadingTenantById) {
    return null;
  }

  return (
    <Box sx={styles.getValue('currency')}>
      {t(`common:currency.${tenantById?.currency}`)}
    </Box>
  );
};

const styles = new SxStyles({
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 'fontWeightBold',
    fontSize: '22px',
    lineHeight: '22px',
  },
});
