import { FC } from 'react';
import { useRouter } from 'next/router';
import { TFunction } from 'next-i18next';
import { DEFAULT_LOCALE } from 'next-i18next.config';
import SliceZone from 'next-slicezone';

import { api } from '@api/index';
import { HomePageDoc } from '@api/prismic/static-pages/types';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { AvailableLocales } from '@lib/route/types';
import { TENANT_CONFIG } from '@lib/tenants/config/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { SliceResolver } from '@ui/slice-machine/SliceResolver';

interface Props {
  t: TFunction;
  data: HomePageDoc['data'] | null;
}

export const HomeGuest: FC<Props> = (props) => {
  const { data } = props;
  const { locale = DEFAULT_LOCALE } = useRouter();
  const { tenant } = useTenantProps();
  const tenantName = tenant.config.setup.name;

  const {
    data: refetchedSlices,
    isFetching,
    isLoading,
  } = useApiQuery(
    () =>
      api.prismic.staticPages.getHomePage(
        TENANT_CONFIG[tenantName],
        locale as AvailableLocales,
      ),
    {
      enabled: data === null,
      queryKey: [CACHE_KEYS.homePageSlice(tenantName)],
    }, // Needed for post-logout page load (next/link SPA behaviour)
  );

  if (isFetching || isLoading) {
    return (
      <ContentSpacer>
        <CenteredLoader />
      </ContentSpacer>
    );
  }

  return (
    <SliceZone
      slices={data?.slices ?? refetchedSlices?.data.slices}
      resolver={SliceResolver}
    />
  );
};
