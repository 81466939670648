import { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { APP_ROUTES } from '@lib/route/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useTenantById } from '@lib/tenants/useTenantById';
import { Box, Typography, useTheme } from '@mui/material';
import { Button } from '@ui/buttons/Button';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';
import { PriceFormat } from '@ui/price/PriceFormat';

export const BalanceCardContent: FC = () => {
  const { t } = useTranslation();
  const { selectedSubscription, isFetching, isLoading, isError } =
    useSubscriptions();
  const router = useRouter();
  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    isFetching: isBalanceFetching,
  } = useRequestBalance(CACHE_KEYS.accountBalance, !!selectedSubscription);

  const { data: tenantById, isLoading: isLoadingTenantById } = useTenantById(
    CACHE_KEYS.tenantById,
  );
  const { tenant } = useTenantProps();
  const theme = useTheme();
  const specificBackgroundColor =
    tenant.theme?.specifics?.authHomePageCard?.backgroundColor;

  if (
    isFetching ||
    isLoading ||
    isBalanceLoading ||
    isBalanceFetching ||
    isLoadingTenantById
  ) {
    return <CenteredLoader />;
  }

  if (isError) {
    return <ErrorPlaceholder />;
  }

  if (!selectedSubscription) {
    return <NoDataPlaceholder title={t('common:errors.noActivePackages')} />;
  }

  return (
    <DefaultCard
      noBorder={false}
      bgcolor={specificBackgroundColor || '#E8F5FF'}
      borderColor={theme.palette.primary.main}
      height="220px"
    >
      <Box
        fontSize="26px"
        fontWeight={600}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <PriceFormat
          price={balanceData?.cashBalance?.outstandingAmount}
          currency={tenantById?.currency}
        />
        <Typography fontSize={16}>
          {t('home:auth.balance.currentBalance')}
        </Typography>
        <Button
          sx={{ mt: 'auto' }}
          size="small"
          onClick={() => router.push(APP_ROUTES.PROFILE.SPENDING_REPORT.path)}
        >
          {t('home:auth.userProfileMenu.spendingReport')}
        </Button>
      </Box>
    </DefaultCard>
  );
};
