import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import { api } from '@api/index';
import { PostSubmitTopUpDto } from '@api/ocb-digital/order/types';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { usePaymentChecker } from '@lib/payment/usePaymentChecker';
import { usePaymentCheckOnDepChanges } from '@lib/payment/usePaymentCheckOnDepChanges';
import { withoutQueryParams, withQueryParams } from '@lib/route/queryParams';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useTenantById } from '@lib/tenants/useTenantById';
import { yupResolver } from '@hookform/resolvers/yup';
import { PurchaseQueryParams } from '@templates/purchase/common/types';
import { RyftPaymentModal } from '@templates/purchase/payment-modals/ryft-payment-modal/RyftPaymentModal';
import { Button } from '@ui/buttons/Button';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { ContentModal } from '@ui/content-modal/ContentModal';

import { DEFAULT_VALUES } from './top-up-form/constants';
import { schema } from './top-up-form/schema';
import { TopUpForm } from './top-up-form/TopUpForm';
import { TopUpLoader } from './top-up-form/TopUpLoader';
import { TopUpFormValues, TopUpSubmitMutationDto } from './top-up-form/types';
import { BalanceCardContent } from './BalanceCardContent';
import { PayPalPaymentModal } from '@templates/purchase/payment-modals/paypal-payment-modal/PayPalPaymentModal';
import {
  AvailablePaymentModals,
  SupportedPaymentTypes,
} from '@lib/payment/types';

export const BalanceCard: FC = () => {
  const { t } = useTranslation();
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const formSubmitCb = useApiMutation(requestOnlinePayment);
  const router = useRouter();
  const orderId = (router.query as PurchaseQueryParams).orderId ?? '';
  const { selectedSubscription } = useSubscriptions();
  const { tenant } = useTenantProps();
  const topUpDisabled = useMemo(
    () => !(selectedSubscription?.status === 'ACTIVE'),
    [selectedSubscription],
  );
  const [isRyftModalOpen, setIsRyftModalOpen] = useState(false);
  const [isPayPalModalOpen, setIsPayPalModalOpen] = useState(false);

  const {
    fetchOrder,
    isLoading: isChecking,
    data,
  } = usePaymentChecker({
    onRedirect: handleRedirect,
    onPaymentSuccess: handlePaymentSuccess,
    onOpenPaymentModal: onOpenPaymentModal,
  });
  const isProcessing = isChecking && orderId;
  const isSubmitting = formSubmitCb.isPending || isChecking;
  const { data: tenantById, isLoading: isLoadingTenantById } = useTenantById(
    CACHE_KEYS.tenantById,
  );
  usePaymentCheckOnDepChanges(fetchOrder, orderId);

  const formMethods = useForm<TopUpFormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <DefaultCard
      title={t('home:auth.balance.title')}
      noBorder={false}
      button={
        <Button
          disabled={topUpDisabled}
          onClick={() => setIsTopUpModalOpen(true)}
        >
          {t('home:auth.balance.topUp.button.topUp')}
        </Button>
      }
    >
      <BalanceCardContent />
      <ContentModal
        titleLabel={t('home:auth.balance.topUp.title')}
        isOpen={isTopUpModalOpen}
        onCancel={onTopUpModalClose}
        showCloseModalIcon
      >
        {isProcessing || isLoadingTenantById ? (
          <TopUpLoader />
        ) : (
          <FormProvider {...formMethods}>
            <TopUpForm
              onSubmit={handleFormSubmit}
              isSubmitting={isSubmitting}
            />
            <PayPalPaymentModal
              open={isPayPalModalOpen}
              onClose={() => setIsPayPalModalOpen(false)}
              orderId={orderId}
              onPaymentApprove={handlePaymentSuccess}
            />
            <RyftPaymentModal
              open={isRyftModalOpen}
              clientSessionId={data?.orderPayment.clientSessionId}
              onClose={closeModals}
              totalUpfront={formMethods.watch('amount')}
              onCheckPaymentStatus={handlePaymentSuccess}
            />
          </FormProvider>
        )}
      </ContentModal>
    </DefaultCard>
  );

  function onTopUpModalClose() {
    setIsTopUpModalOpen(false);
    formMethods.reset(DEFAULT_VALUES);
  }

  function closeModals() {
    setIsRyftModalOpen(false);
    onTopUpModalClose();
  }

  function onOpenPaymentModal(availablePaymentModal: AvailablePaymentModals) {
    if (availablePaymentModal === 'PAYPAL') {
      setIsPayPalModalOpen(true);
    } else if (availablePaymentModal === 'RYFT') {
      setIsRyftModalOpen(true);
    }
  }

  async function requestOnlinePayment({
    formValues,
  }: TopUpSubmitMutationDto): Promise<void> {
    if (!selectedSubscription || !tenantById) {
      throw new Error(t('common:errors.unexpected'));
    }

    try {
      const callbackUrl = withQueryParams(window.location.href, {
        orderId: 'ORDER_ID',
      });

      const dto: PostSubmitTopUpDto = {
        orderPaymentReq: {
          callbackUrl,
          paymentType: SupportedPaymentTypes.Online,
        },
        subscriptionId: selectedSubscription.id,
        moneyAmount: {
          amount: formValues.amount,
          currency: tenantById?.currency ?? '',
        },
      };

      const res = await api.ocbDigital.order.postSubmitAuthTopUp(dto, tenant);
      await fetchOrder({
        orderId: res.id,
      });
    } catch (err) {
      throw err;
    }
  }

  function handleFormSubmit(formValues: TopUpFormValues): void {
    formSubmitCb.mutate({ formValues });
  }

  function handleRedirect(redirectUrl: string): void {
    router.push(redirectUrl);
  }

  async function handlePaymentSuccess() {
    toast.success(t('home:auth.balance.notifications.topUpSuccess'), {
      autoClose: false,
    });
    router.replace(withoutQueryParams(router.asPath));
  }
};
