import { number, object } from 'yup';

const shape = {
  amount: number()
    .min(1, ({ min }) => ({
      key: 'common:validation.number.min',
      values: { count: min },
    }))
    .required('common:validation.fieldRequired'),
};

export const schema = object<typeof shape>().shape(shape);
