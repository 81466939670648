import dynamic from 'next/dynamic';

import { NavItemCategorySliceProps } from '@ui/slice-machine/slices/nav-item-category-slice/NavItemCategorySlice';

const NavItemCategorySlice = dynamic<NavItemCategorySliceProps>(() =>
  import(
    '@ui/slice-machine/slices/nav-item-category-slice/NavItemCategorySlice'
  ).then((val) => val.NavItemCategorySlice)
);

export default NavItemCategorySlice;
