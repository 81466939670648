import dynamic from 'next/dynamic';

import { HeaderMenuItemOrderSliceProps } from '@ui/slice-machine/slices/header-menu-item-order-slice/HeaderMenuItemOrderSlice';

const HeaderMenuItemOrderSlice = dynamic<HeaderMenuItemOrderSliceProps>(() =>
  import(
    '@ui/slice-machine/slices/header-menu-item-order-slice/HeaderMenuItemOrderSlice'
  ).then((val) => val.HeaderMenuItemOrderSlice)
);

export default HeaderMenuItemOrderSlice;
