import dynamic from 'next/dynamic';

import { RichTextSliceProps } from '@ui/slice-machine/slices/rich-text-slice/RichTextSlice';

const RichTextSlice = dynamic<RichTextSliceProps>(() =>
  import('@ui/slice-machine/slices/rich-text-slice/RichTextSlice').then(
    (val) => val.RichTextSlice
  )
);

export default RichTextSlice;
