// This file is generated by Slice Machine. Update with care!

import Banner from './Banner';
import Card from './Card';
import Carousel from './Carousel';
import DrawerMenuItemOrder from './DrawerMenuItemOrder';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import HeaderMenuItemOrder from './HeaderMenuItemOrder';
import Images from './Images';
import LinkList from './LinkList';
import Modal from './Modal';
import NavItemCategory from './NavItemCategory';
import RichText from './RichText';
import RichTextHtml from './RichTextHtml';
import ScrollToLine from './ScrollToLine';
import SectionIntro from './SectionIntro';
import Tiles from './Tiles';

export {
  Banner,
  Card,
  Carousel,
  DrawerMenuItemOrder,
  FrequentlyAskedQuestions,
  HeaderMenuItemOrder,
  Images,
  LinkList,
  Modal,
  NavItemCategory,
  RichText,
  RichTextHtml,
  ScrollToLine,
  SectionIntro,
  Tiles,
};

export const components = {
  banner: Banner,
  card: Card,
  carousel: Carousel,
  drawer_menu_item_order: DrawerMenuItemOrder,
  frequently_asked_questions: FrequentlyAskedQuestions,
  header_menu_item_order: HeaderMenuItemOrder,
  images: Images,
  link_list: LinkList,
  modal: Modal,
  nav_item_category: NavItemCategory,
  rich_text: RichText,
  rich_text_html: RichTextHtml,
  scroll_to_line: ScrollToLine,
  section_intro: SectionIntro,
  tiles: Tiles,
};
