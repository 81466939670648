import { LinkListSliceProps } from '@ui/slice-machine/slices/link-list-slice/LinkListSlice';
import dynamic from 'next/dynamic';

const LinkListSlice = dynamic<LinkListSliceProps>(() =>
  import('@ui/slice-machine/slices/link-list-slice/LinkListSlice').then(
    (val) => val.LinkListSlice,
  ),
);

export default LinkListSlice;
