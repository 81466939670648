import { useEffect } from 'react';

import { OrderFetch } from './types';

export const usePaymentCheckOnDepChanges = (
  fetchOrder: OrderFetch,
  orderId: string | undefined,
  requestId?: string
): void => {
  useEffect(() => {
    if (orderId) {
      fetchOrder({
        orderId: orderId,
        requestId: requestId,
      });
    }
  }, [orderId, requestId, fetchOrder]);
};
