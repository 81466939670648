import dynamic from 'next/dynamic';

import { RichTextHtmlSliceProps } from '@ui/slice-machine/slices/rich-text-html-slice/RichTextHtmlSlice';

const RichTextHtmlSlice = dynamic<RichTextHtmlSliceProps>(() =>
  import(
    '@ui/slice-machine/slices/rich-text-html-slice/RichTextHtmlSlice'
  ).then((val) => val.RichTextHtmlSlice)
);

export default RichTextHtmlSlice;
