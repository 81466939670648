import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { Grid } from '@mui/material';
import { CurrencySymbol } from '@templates/common/symbols/CurrencySymbol';
import { Button } from '@ui/buttons/Button';
import { NumberInput } from '@ui/form/number-input/NumberInput';

import { TopUpFormValues } from './types';

interface Props {
  isSubmitting: boolean;
  onSubmit: (formValues: TopUpFormValues) => void;
}

export const TopUpForm: FC<Props> = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const formMethods = useFormContext<TopUpFormValues>();

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumberInput
            name="amount"
            thousandSeparator=""
            placeholder={t('home:auth.balance.form.label.amount')}
            decimalScale={0}
            allowNegative={false}
            control={formMethods.control}
            inputProps={{ maxLength: 4, 'data-testid': 'amount-input' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            loading={isSubmitting}
            data-testid="submit"
            startIcon={<CurrencySymbol />}
            sx={{ width: '40%', minWidth: 'min-content' }}
          >
            {t('home:auth.balance.button.topUp')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
