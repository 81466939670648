import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { CenteredLoader } from '@ui/loaders/CenteredLoader';

export const TopUpLoader: FC = () => {
  const { t } = useTranslation();

  return <CenteredLoader title={t('home:auth.balance.loadingTitle')} />;
};
