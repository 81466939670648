import dynamic from 'next/dynamic';

import { ImagesSliceProps } from '@ui/slice-machine/slices/images-slice/ImagesSlice';

const ImagesSlice = dynamic<ImagesSliceProps>(() =>
  import('@ui/slice-machine/slices/images-slice/ImagesSlice').then(
    (val) => val.ImagesSlice
  )
);

export default ImagesSlice;
