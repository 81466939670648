import dynamic from 'next/dynamic';

import { CardSliceProps } from '@ui/slice-machine/slices/card-slice/CardSlice';

const CardSlice = dynamic<CardSliceProps>(() =>
  import('@ui/slice-machine/slices/card-slice/CardSlice').then(
    (val) => val.CardSlice
  )
);

export default CardSlice;
