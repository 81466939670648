import dynamic from 'next/dynamic';

import { DrawerMenuItemOrderSliceProps } from '@ui/slice-machine/slices/drawer-menu-item-order-slice/DrawerMenuItemOrderSlice';

const DrawerMenuItemOrderSlice = dynamic<DrawerMenuItemOrderSliceProps>(() =>
  import(
    '@ui/slice-machine/slices/drawer-menu-item-order-slice/DrawerMenuItemOrderSlice'
  ).then((val) => val.DrawerMenuItemOrderSlice)
);

export default DrawerMenuItemOrderSlice;
