import { ReactElement, ReactNode } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { getFormError } from '@lib/form/errors';
import { SxStyles } from '@lib/theme/sxTheme';
import {
  Box,
  FormControl,
  FormControlProps,
  InputBaseProps,
} from '@mui/material';

import { TextInput } from '../text-input/TextInput';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<NumericFormatProps, 'min' & 'max'> & {
  name: TName;
  label?: string | ReactNode;
  control: Control<TFieldValues>;
  decimalSeparator?: string;
  thousandSeparator?: string | boolean;
  variant?: FormControlProps['variant'];
  sideIcon?: ReactNode;
  inputProps?: InputBaseProps['inputProps'];
};

export const NumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
): ReactElement | null => {
  const {
    name,
    label,
    decimalSeparator = ',',
    thousandSeparator = '.',
    variant = 'outlined',
    sideIcon,
    disabled,
    ...rest
  } = props;
  const formMethods = useFormContext();
  const { formState, register } = formMethods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, onBlur, ...restRegister } = register(name);
  const error = getFormError(name, formState.errors);
  const dynamicStyles = styles({ hasErrors: !!error });

  const field = (
    <FormControl
      error={!!error}
      component="fieldset"
      variant={variant}
      fullWidth={true}
      aria-labelledby={name}
    >
      <TextInput
        name={name}
        variant={variant}
        control={formMethods.control}
        label={label}
        id={name}
        data-testid={name}
        disabled={disabled}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumericFormat as any,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputProps: {
            decimalSeparator,
            thousandSeparator,
            ...rest,
            ...restRegister,
          },
        }}
      />
    </FormControl>
  );

  if (sideIcon) {
    return (
      <Box display="flex" alignItems="flex-end">
        <Box sx={dynamicStyles.getValue('sideIconContainer')}>{sideIcon}</Box>
        {field}
      </Box>
    );
  }

  return field;
};

interface StyleProps {
  hasErrors: boolean;
}

const styles = ({ hasErrors }: StyleProps) =>
  new SxStyles({
    sideIconContainer: {
      display: 'flex',
      overflow: 'hidden',
      width: 32,
      alignItems: 'center',
      margin: (theme) => {
        if (hasErrors) {
          return 'auto 8px 25px 0px';
        }

        return theme.spacing('auto', 1, 0.25, 0);
      },
      color: 'primary.main',
    },
  });
